import {
  configureStore,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";

import { productsData } from "../ShopComponents/ShopApi/ShopApi";
import { toast } from "react-toastify";

const productSlice = createSlice({
  name: "myStore",
  initialState: {
    products: [],
    totalQuantity: 0,
  },
  reducers: {
    addToCartProduct(state, action) {
      const newProduct = action.payload;
      state.totalQuantity++;
      const findProduct = state.products.find(
        (proId) => proId.id === newProduct.id
      );
      if (!findProduct) {
        state.products.push({
          ...newProduct,
          quantity: 1,
          subTotal: newProduct.price,
        });
        toast.success("Product added to the Cart", {
          position: "bottom-left",
        });
      } else {
        findProduct.quantity++;
        findProduct.subTotal += newProduct.price;
        toast.info("Product Quantity Increased", {
          position: "bottom-left",
        });
      }
    },
    removeToCartProduct(state, action) {
      const productId = action.payload;
      state.products = state.products.filter((pro) => pro.id !== productId.id);
      state.totalQuantity--;
      toast.info("Product Remove from the Cart", {
        position: "bottom-left",
      });
    },
  },
});

export const fetchData = createAsyncThunk("fetchProduct", async () => {
  try {
    const response = await fetch(
      "https://api.edamam.com/api/recipes/v2?type=public&q=chiken&app_id=1b2eeec1&app_key=122594b877a8e24ef37ef15f44ba9ea4"
    );
    const data = await response.json();
    return data.hits;
  } catch (error) {
    return error;
  }
});

const ApiDataSlice = createSlice({
  name: "ApiData",
  initialState: { userData: [], loading: "idle", error: null },

  reducers: {},
  extraReducers: {
    [fetchData.pending]: (state, action) => {
      state.loading = "loading";
    },
    [fetchData.fulfilled]: (state, action) => {
      state.loading = "succeeded";
      state.userData = action.payload;
    },
    [fetchData.rejected]: (state, action) => {
      state.loading = "failed";
      state.error = action.payload.errorMessage;
    },
  },
});

const productApiSlice = createSlice({
  name: "productApi",
  initialState: {
    productsData: productsData,
    loading: "idle",
    error: "",
    featuredProducts: [],
    newProducts: [],
    topProducts: [],
    categoryProducts: [],
  },
  reducers: {
    featured(state, action) {
      state.featuredProducts = state.productsData.filter(
        (pro) => pro.label === "featured"
      );
    },
    newProducts(state, action) {
      state.newProducts = state.productsData.filter(
        (pro) => pro.label === "new"
      );
    },
    topSellersProducts(state, action) {
      state.topProducts = state.productsData.filter(
        (pro) => pro.label === "top sellers"
      );
    },
    categoryProducts(state, action) {
      state.categoryProducts = state.productsData.filter(
        (pro) => pro.category === action.payload.category
      );
    },
  },
});

export const store = configureStore({
  reducer: {
    products: productSlice.reducer,
    fetchProducts: ApiDataSlice.reducer,
    productApiData: productApiSlice.reducer,
  },
});

export const productActions = productSlice.actions;
export const productApiActions = productApiSlice.actions;
