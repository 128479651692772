import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./ShopStore/store";

// import { store } from "./reduxStore/toolkit/toolkit";
// import { store } from "./reduxStore/toolkit/shopKit";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Provider store={store}>
  //   <App />
  // </Provider>
  // <Provider store={store}>
  //   <App />
  // </Provider>
  <>
    <Provider store={store}>
      <App />
    </Provider>
  </>
);
