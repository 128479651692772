import React from "react";
import SaleProduct from "../SaleProduct/SaleProduct";
import "./product.css";
import ProductsTab from "../ProductTabs/ProductsTab";

const Products = () => {
  return (
    <>
      <section className="products-body">
        <div className="products-body-wrapper section-spacing">
          <SaleProduct />
          <ProductsTab />
        </div>
      </section>
    </>
  );
};

export default Products;
