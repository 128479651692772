import React from "react";
import Header from "../ShopComponents/Navbar/Header";
import Hero from "../ShopComponents/Hero/Hero";
import ProductsCategories from "../ShopComponents/ProductsCategory/ProductsCategories";
import FeaturedProducts from "../ShopComponents/FeaturedProducts/FeaturedProducts";
import Footer from "../ShopComponents/Footer/Footer";
import Products from "../ShopComponents/ProductBody/Products/Products";
import ProductDesign from "../ShopComponents/ShareComp/ProductDesign/ProductDesign";
import NewProductsTab from "../ShopComponents/ProductBody/NewProductsTab/NewProductsTab";

const Home = () => {
  return (
    <>
      <section className="homepage">
        <Header />
        <Hero />
        <Products />
        <ProductsCategories />
        <FeaturedProducts />

        <Footer />
      </section>
    </>
  );
};

export default Home;
