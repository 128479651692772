import React, { useEffect } from "react";
import ProductDesign from "../../ShareComp/ProductDesign/ProductDesign";
import { useDispatch, useSelector } from "react-redux";
import "./newTab.css";
import { productActions, productApiActions } from "../../../ShopStore/store";
import { productsData } from "../../ShopApi/ShopApi";

const NewProductsTab = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productApiActions.newProducts());
  }, [dispatch]);
  const data = useSelector((state) => state.productApiData.newProducts);

  const addProduct = (product) => {
    dispatch(productActions.addToCartProduct(product));
  };

  return (
    <>
      <section className="new-products-tab">
        <div className="new-products-tab-wrapper">
          {data.map((pro) => {
            return (
              <section className="product-design" key={pro.id}>
                <div className="product-design-wrapper">
                  <img src={pro.image} alt={pro.title} />
                  <h6>{pro.title}</h6>
                  <p>${pro.price}</p>
                  <button
                    className="btn-primary"
                    onClick={() => addProduct(pro)}
                  >
                    Add to Cart
                  </button>
                </div>
              </section>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default NewProductsTab;
