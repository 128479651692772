import React from "react";
import "./hero.css";

const ShopHero = () => {
  return (
    <>
      <section className="shop-hero">
        <div
          className="shop-hero-wrapper"
          style={{ backgroundImage: "url(images/image3.jpg)" }}
        >
          <h1>Shop</h1>
        </div>
      </section>
    </>
  );
};

export default ShopHero;
