import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchData, productActions } from "../../ShopStore/store";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./feature.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
const FeaturedProducts = () => {
  const productData = useSelector((state) => state.products.products);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);
  const data = useSelector((state) => state.fetchProducts.userData);
  console.log("Fatch:", data);
  const loading = useSelector((state) => state.fetchProducts.loading);
  const error = useSelector((state) => state.fetchProducts.error);

  // const dispatch = useDispatch();
  const addProduct = (productData) => {
    dispatch(productActions.addToCartProduct(productData));
  };
  const addData = useSelector((state) => state.products.products);
  console.log(addData);
  // console.log(addProduct);
  const breakpoints = {
    // When window width is >= 768px

    767: {
      slidesPerView: 2,
    },
    // When window width is >= 1024px
    1024: {
      slidesPerView: 4,
    },
  };

  return (
    <>
      <section className="feature-products">
        <div className="products-categories-wrapper section-spacing">
          <h2>Best Recipes</h2>

          <Swiper
            breakpoints={breakpoints}
            modules={[Pagination, Navigation]}
            slidesPerGroup={1}
            // loop={true}
            navigation={true}
            // pagination={{
            //   clickable: true,
            // }}
            autoplay={{
              delay: 500, // Autoplay delay in milliseconds
              disableOnInteraction: false, // Allow autoplay to continue after user interaction
            }}
            className="mySwiper"
          >
            {loading === "loading" && <p>Loading...</p>}
            {loading === "succeeded" &&
              data.map((pro, ind) => {
                return (
                  <>
                    <SwiperSlide key={ind}>
                      <div className="feature-products-slides">
                        <img src={pro.recipe.image} alt="" />
                        <h5>{pro.recipe.label}</h5>
                        <p>{pro.recipe.label}</p>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default FeaturedProducts;
