import React from "react";
import Header from "../ShopComponents/Navbar/Header";
import Cart from "../ShopComponents/Cart/Cart";
import Footer from "../ShopComponents/Footer/Footer";

const CartPage = () => {
  return (
    <>
      <Header />
      <Cart />
      <Footer />
    </>
  );
};

export default CartPage;
