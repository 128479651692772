import React from "react";
import Shop from "../ShopComponents/Shop/Shop";

const ShopPage = () => {
  return (
    <>
      <Shop />
    </>
  );
};

export default ShopPage;
