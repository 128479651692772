import React from "react";
import {
  AiOutlineClose,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import "./cart.css";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../ShopStore/store";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const Cart = () => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.products.products);
  const total = data.reduce((accu, product) => accu + product.price, 0);
  console.log(total);
  const dispatch = useDispatch();
  const removeProduct = (product) => {
    dispatch(productActions.removeToCartProduct(product));
  };

  const processOrder = () => {
    if (data.length === 0) {
      toast.info("Cart is Empty", {
        position: "bottom-left",
      });
    } else {
      toast.success("Order Placed", {
        position: "bottom-left",
      });
      navigate("/");
    }
  };
  return (
    <>
      <section-cart-hero>
        <div
          className="cart-hero-wrapper"
          style={{ backgroundImage: "url(images/images1.jpg)" }}
        >
          <h1>Shopping Cart</h1>
        </div>
        {data.length === 0 && (
          <div className="empty-cart section-spacing">
            <h3>No Product in the Cart</h3>
            <h5>
              Return to the <Link to="/shop">Shop</Link>
            </h5>
          </div>
        )}
        {data.length !== 0 && (
          <section className="cart-data">
            <div className="cart-wrapper section-spacing">
              <div className="cart-left-side">
                <div className="cart-table">
                  <table>
                    <thead>
                      <th></th>
                      <th></th>
                      <th className="product-name">Product</th>
                      <th className="product-price">Price</th>
                      <th className="product-qty">Quantity</th>
                      <th className="product-stotal">Subtotal</th>
                    </thead>
                    <tbody>
                      {data.map((prod, ind) => {
                        return (
                          <>
                            <tr className="cart-item">
                              <td className="product-remove">
                                <AiOutlineClose
                                  onClick={() => removeProduct(prod)}
                                />
                              </td>
                              <td className="product-img">
                                <img src={prod.image} alt={prod.title} />
                              </td>
                              <td className="product-name">{prod.title}</td>
                              <td className="product-price">${prod.price}</td>
                              <td className="product-qty">
                                <span className="count-inc">
                                  <AiOutlinePlusCircle />
                                </span>
                                <span className="count-data">
                                  {prod.quantity}
                                </span>
                                <span className="count-dec">
                                  <AiOutlineMinusCircle />
                                </span>
                              </td>
                              <td className="product-stotal">
                                ${prod.subTotal}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cart-right-side">
                <div className="cart-right-content">
                  <h3>CART TOTALS</h3>
                  <div className="cart-total">
                    <div className="total-text">
                      <h4>Total</h4>
                    </div>
                    <div className="total-price">
                      <h4>${total}</h4>
                    </div>
                  </div>
                  <div className="proceed">
                    <button className="proceed-btn" onClick={processOrder}>
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </section-cart-hero>
    </>
  );
};

export default Cart;
