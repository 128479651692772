import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./category.css";
import { Navigation, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, productApiActions } from "../../ShopStore/store";
import ProductDesign from "../ShareComp/ProductDesign/ProductDesign";

const ProductsCategories = () => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchData());
  // }, [dispatch]);
  // const data = useSelector((state) => state.fetchProducts.userData);
  // useEffect(() => {
  //   dispatch(productApiActions.categoryProducts());
  // }, [dispatch]);

  // const categoryPro = (pro) => {
  //   dispatch(productApiActions.categoryProducts(pro));
  // };
  // const categoryProduct = useSelector(
  //   (state) => state.productApiSlice.productsData
  // );
  // const data = useSelector((state) => state.productApiData.categoryProducts);
  // console.log(data);

  // console.log(categoryProduct);
  // console.log("data:", data);

  const breakpoints = {
    // When window width is >= 768px

    360: {
      slidesPerView: 2,
    },
    // When window width is >= 1024px
    1024: {
      slidesPerView: 4,
    },
  };
  return (
    <>
      <section className="products-category">
        <div className="products-categories-wrapper section-spacing">
          <h2>TOP CATEGORIES</h2>
          <Swiper
            breakpoints={breakpoints}
            slidesPerGroup={1}
            navigation={true}
            loop={true}
            modules={[Pagination, Navigation]}
            // pagination={{
            //   clickable: true,
            // }}
            className="mySwiper"
          >
            <>
              <SwiperSlide>
                <div className="products-categories-slides">
                  <a href="/shop">
                    <img src="images/product-11.jpg" alt="clock" />
                    <h6>Clocks</h6>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="products-categories-slides">
                  <a href="/shop">
                    <img src="images/product-22.jpg" alt="clock" />
                    <h6>Furniture</h6>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="products-categories-slides">
                  <a href="/shop">
                    <img src="images/product-6.jpg" alt="clock" />
                    <h6>Lighting</h6>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="products-categories-slides">
                  <a href="/shop">
                    <img src="images/product-14.jpg" alt="clock" />
                    <h6>Accessories</h6>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="products-categories-slides">
                  <a href="/shop">
                    <img src="images/product-15.jpg" alt="clock" />
                    <h6>Cooking</h6>
                  </a>
                </div>
              </SwiperSlide>
            </>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default ProductsCategories;
