// import ExpenseForm from "./Components/ExpenseForm/ExpenseForm";
// import Assignment1 from "./Components/Assignments/Assignment1";
// import Assign from "./Components/Assignments/Assignment2/Assign";
// import Context from "./Components/Context/Context";
// import Styled from "./Components/StyledComp/Styled";
// import Test from "./Components/TestForm/Test";
// import Service from "./Components/Service/Service";

import CartPage from "./ShopPages/CartPage";
import Home from "./ShopPages/Home";
import ShopPage from "./ShopPages/ShopPage";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./responsive.css";

// import Main from "./Pages/NavPages/Main";

// import Shop from "./Components/ShopingCart/Shop";

// import Form from "./Components/FormValidation/Form";

// import ToolkitAssign from "./Components/Redux/ToolkitAssign";

// import { Assign } from "./Components/Redux/Assign";

// import ShowCounterApp from "./Components/hooks/ShowCounterApp";

// import Shop from "./Pages/Shop/Shop";
// import ShopProvider from "./store/ShopProvider";

// import Services from "./Components/Services/Services";

function App() {
  return (
    <>
      {/* <ExpenseForm /> */}
      {/* <Test /> */}
      {/* <Styled /> */}
      {/* <Assignment1 /> */}
      {/* <Assign /> */}
      {/* <Context /> */}
      {/* <Services /> */}

      {/* <ShopProvider>
        <Shop />
      </ShopProvider> */}

      {/* <ShowCounterApp /> */}

      {/* <Assign /> */}

      {/* <ToolkitAssign /> */}
      {/* <Form /> */}
      {/* <Shop /> */}

      {/* <Main /> */}
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />}>
            Home
          </Route>
          <Route path="/cart" element={<CartPage />}>
            Cart
          </Route>
          <Route path="/shop" element={<ShopPage />}>
            Shop
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
