import React from "react";
import ShopHero from "./ShopHero/ShopHero";
import Header from "../Navbar/Header";
import Footer from "../Footer/Footer";
import ShopProduct from "./ShopProduct/ShopProduct";
import FilterProducts from "./Filter/FilterProducts";
import "./shop.css";

const Shop = () => {
  return (
    <>
      <Header />
      <ShopHero />
      <div className="shop-body section-spacing">
        {/* <FilterProducts /> */}
        <ShopProduct />
      </div>
      <Footer />
    </>
  );
};

export default Shop;
