import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./tabs.css";
import NewProductsTab from "../NewProductsTab/NewProductsTab";
import FeaturedPro from "../FeaturedPro/FeaturedPro";
import TopPro from "../TopPro/TopPro";
const ProductsTab = () => {
  const [activetab, setActiveTab] = useState("second");
  return (
    <>
      <section className="product-tabs">
        <div className="product-tabs-wrapper">
          <div className="product-tabs-heading">
            <h2>SPECIAL OFFER</h2>
          </div>
          <div className="product-tab-txt">
            <p
              className={activetab === "second" ? "active" : ""}
              onClick={() => setActiveTab("second")}
            >
              Featured
            </p>

            <p
              className={activetab === "first" ? "active" : ""}
              onClick={() => setActiveTab("first")}
            >
              New
            </p>
            <p
              className={activetab === "third" ? "active" : ""}
              onClick={() => setActiveTab("third")}
            >
              Top Sellers
            </p>
          </div>
        </div>
        <div>{activetab === "first" && <NewProductsTab title="1" />}</div>
        <div>{activetab === "second" && <FeaturedPro />}</div>
        <div>{activetab === "third" && <TopPro />}</div>
      </section>
    </>
  );
};

export default ProductsTab;
