import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { productApiActions } from "../../../ShopStore/store";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./salePro.css";
import ProductDesign from "../../ShareComp/ProductDesign/ProductDesign";
import { productActions } from "../../../ShopStore/store";

const SaleProduct = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productApiActions.topSellersProducts());
  }, [dispatch]);
  const data = useSelector((state) => state.productApiData.topProducts);
  const addProduct = (product) => {
    dispatch(productActions.addToCartProduct(product));
  };
  return (
    <>
      <section className="sale-products">
        <div className="sale-products-wrapper">
          <h2>SALE PRODUCTS</h2>
          <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            modules={[Pagination, Navigation]}
            navigation={true}
          >
            {data.map((pro) => {
              return (
                <>
                  <SwiperSlide>
                    <section className="product-design" key={pro.id}>
                      <div className="product-design-wrapper">
                        <img src={pro.image} alt={pro.title} />
                        <h6>{pro.title}</h6>
                        <p>${pro.price}</p>
                        <button
                          className="btn-primary"
                          onClick={() => addProduct(pro)}
                        >
                          Add to Cart
                        </button>
                      </div>
                    </section>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default SaleProduct;
