import React from "react";
import {
  AiOutlineTags,
  AiOutlineMan,
  AiOutlineProject,
  AiOutlineWallet,
} from "react-icons/ai";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <section className="footer-top-bar">
        <div className="footer-top-bar-wrapper">
          <div className="footer-top-bar-content">
            <div className="footer-top-bar-inner-content">
              <div className="footer-top-bar-icon">
                <AiOutlineTags />
              </div>
              <div className="footer-top-bar-text">
                <h5>Free Shipping</h5>
                <p>No one rejects, dislikes.</p>
              </div>
            </div>
            <div className="footer-top-bar-inner-content">
              <div className="footer-top-bar-icon">
                <AiOutlineWallet />
              </div>
              <div className="footer-top-bar-text">
                <h5>24/7 Support</h5>
                <p>No one rejects, dislikes.</p>
              </div>
            </div>
            <div className="footer-top-bar-inner-content">
              <div className="footer-top-bar-icon">
                <AiOutlineProject />
              </div>
              <div className="footer-top-bar-text">
                <h5>Online Payment</h5>
                <p>No one rejects, dislikes.</p>
              </div>
            </div>
            <div className="footer-top-bar-inner-content">
              <div className="footer-top-bar-icon">
                <AiOutlineMan />
              </div>
              <div className="footer-top-bar-text">
                <h5>Fast Delivery</h5>
                <p>No one rejects, dislikes.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ==================== Foooter Body ========================== */}

      <section className="footer-body">
        <div className="footer-body-wrapper">
          <div className="footer-body-content">
            <h6>USEFUL LINKS</h6>
            <a href="#">Shop</a>
            <a href="#">About</a>
            <a href="#">Contact</a>
          </div>
          <div className="footer-body-content">
            <h6>OUR STORES</h6>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam
              dicta, illum corporis eveniet, illo quae consequatur quas.
            </p>
          </div>
          <div className="footer-body-content">
            <h6>MORE LINKS</h6>
            <a href="#">Privacy Policy</a>
            <a href="#"> Terms & Conditions </a>
            <a href="#">Home</a>
          </div>
          {/* <div className="footer-body-content">
            <h6>JOIN OUR NEWSLETTER</h6>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque,
              nam.
            </p>
            <input type="text" placeholder="Your email address" />
            <button type="submit">Join</button>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Footer;
