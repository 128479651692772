import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { fetchData } from "../../../ShopStore/store";
import { Link } from "react-router-dom";
import "./product.css";
import { productActions, productApiActions } from "../../../ShopStore/store";

const ShopProduct = () => {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchData());
  // }, [dispatch]);
  // const data = useSelector((state) => state.fetchProducts.userData);
  useEffect(() => {
    dispatch(productApiActions.featured());
    dispatch(productApiActions.newProducts());
  }, [dispatch]);
  const productsData = useSelector(
    (state) => state.productApiData.productsData
  );
  // const featuredproducts = useSelector(
  //   (state) => state.productApiData.featuredProducts
  // );
  // const newProducts = useSelector((state) => state.productApiData.newProducts);
  // console.log(newProducts);
  // console.log(productsData);

  const addCart = (productData) => {
    dispatch(productActions.addToCartProduct(productData));
  };

  return (
    <>
      <section className="shop">
        {productsData.map((pro, ind) => {
          return (
            <>
              <div className="shop-wrapper " key={ind}>
                <img src={pro.image} alt={pro.title} />
                <h5>{pro.title}</h5>
                <p>Price: ${pro.price}</p>
                <Link
                  to="#"
                  className="btn-primary"
                  onClick={() => addCart(pro)}
                >
                  Add to Cart
                </Link>
              </div>
            </>
          );
        })}
      </section>
    </>
  );
};

export default ShopProduct;
