export const productsData = [
  {
    id: "p1",
    title: "Ullamcorper arcu elementum",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-11.jpg",
    category: "Clocks",
    price: 210,
    color: "black",
    label: "new",
  },
  {
    id: "p2",
    title: "Wall clock mint",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-20.jpg",
    category: "Clocks",
    price: 180,
    color: "green",
    label: "new",
  },
  {
    id: "p3",
    title: "Smart watches wood edition",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-1.jpg",
    category: "Clocks",
    price: 300,
    color: "beige",
    label: "new",
  },
  {
    id: "p4",
    title: "Panton tunior chair",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-22.jpg",
    category: "Furniture",
    price: 450,
    color: "green",
    label: "featured",
  },
  {
    id: "p5",
    title: "Wooden naïve chair",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-6.jpg",
    category: "Furniture",
    price: 400,
    color: "black",
    label: "featured",
  },
  {
    id: "p6",
    title: "Slatea suspendisse",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-6.jpg",
    category: "Lighting",
    price: 250,
    color: "white",
    label: "featured",
  },
  {
    id: "p7",
    title: "Light wood consectetur",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-6.jpg",
    category: "Lighting",
    price: 500,
    color: "black",
    label: "featured",
  },
  {
    id: "p8",
    title: "Decoration wooden present",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-14.jpg",
    category: "Accessories",
    price: 160,
    color: " ",
    label: "featured",
  },
  {
    id: "p9",
    title: "Amazing pen cup wood",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-5.jpg",
    category: "Accessories",
    price: 240,
    color: " ",
    label: "featured",
  },
  {
    id: "p10",
    title: "Decoration for table pen",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-7.jpg",
    category: "Accessories",
    price: 200,
    color: " ",
    label: "top sellers",
  },
  {
    id: "p11",
    title: "Mi ad etiam consectetur",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-15.jpg",
    category: "Cooking",
    price: 350,
    color: " ",
    label: "top sellers",
  },
  {
    id: "p12",
    title: "Fames suspendisse parturient",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-18.jpg",
    category: "Cooking",
    price: 450,
    color: " ",
    label: "top sellers",
  },
  {
    id: "p13",
    title: "Lucie Kaas",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-19.jpg",
    category: "Toys",
    price: 250,
    color: " ",
    label: "top sellers",
  },
  {
    id: "p14",
    title: "Wooden Dog",
    desc: "Suspendisse in habitant bibendum nec vel non magnis id enim a platea a consectetur convallis a tempus. Ullamcorper arcu elementum mauris per habitant rutrum aliquam ac aenean tincidunt libero ullamcorper vel facilisis mus fringilla risus.",
    image: "images/product-16.jpg",
    category: "Toys",
    price: 350,
    color: " ",
    label: "top sellers",
  },
];
