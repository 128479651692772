import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productApiActions } from "../../../ShopStore/store";
import ProductDesign from "../../ShareComp/ProductDesign/ProductDesign";
import { productActions } from "../../../ShopStore/store";

const TopPro = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productApiActions.topSellersProducts());
  }, [dispatch]);
  const topProducts = useSelector((state) => state.productApiData.topProducts);
  const addProduct = (product) => {
    dispatch(productActions.addToCartProduct(product));
  };
  return (
    <>
      <section className="top-products">
        <div className="new-products-tab-wrapper">
          {topProducts.map((pro) => {
            return (
              <>
                <section className="product-design" key={pro.id}>
                  <div className="product-design-wrapper">
                    <img src={pro.image} alt={pro.title} />
                    <h6>{pro.title}</h6>
                    <p>${pro.price}</p>
                    <button
                      className="btn-primary"
                      onClick={() => addProduct(pro)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </section>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default TopPro;
