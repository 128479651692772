import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiOutlineSearch,
  AiOutlineShopping,
} from "react-icons/ai";
import "./nav.css";
import "../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../ShopStore/store";
import { AiOutlineAlignRight, AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [open, setOpen] = useState(true);
  const toggleMenu = () => {
    setOpen(!open);
  };
  const count = useSelector((state) => state.products.totalQuantity);
  const dispatch = useDispatch();
  const addCart = () => {
    dispatch(productActions.addToCartProduct);
  };

  const tablet = window.innerWidth <= 1024 ? true : false;
  return (
    <>
      {tablet === false && (
        <>
          <section className="topbar">
            <div className="topbar-wrapper">
              <div className="topbar-text">
                <p>FREE SHIPPING FOR ALL ORDERS OF $150</p>
              </div>
              <div className="top-bar-icons">
                <a href="#">
                  <AiFillFacebook />
                </a>
                <a href="#">
                  <AiFillInstagram />
                </a>
                <a href="#">
                  <AiFillLinkedin />
                </a>
              </div>
            </div>
          </section>

          {/* ================================ Navbar ====================== */}

          <section className="navbar">
            <div className="navbar-wrapper">
              <div className="navbar-content">
                <img src="images/logo.png" alt="logo" />
              </div>

              {/* ============== Search =============== */}
              {/* <div className="navbar-content nav-search-content">
            <div className="navbar-search">
              <input type="text" placeholder="Search for Products" />
            </div>
            <div className="navbar-product-categories">
              <select name="" id="">
                <option value="clocks">Clocks</option>
                <option value="bread">Bread</option>
                <option value="bread">Bread</option>
                <option value="bread">Bread</option>
              </select>
            </div>
            <div className="nav-bar-search">
              <AiOutlineSearch />
            </div>
          </div> */}
              <div className="navbar-content nav-search-content">
                <div className="navbar-search">
                  <input type="text" placeholder="Search for Products" />
                </div>
                <div className="nav-bar-search">
                  <AiOutlineSearch />
                </div>
              </div>

              <div className="navbar-content cart">
                <Link to="/cart">
                  <AiOutlineShopping onClick={addCart} />
                  <span>{count}</span>
                </Link>
              </div>
            </div>
          </section>

          {/* ================================ Main Navbar ====================== */}

          <section className="main-navbar">
            <div className="main-navbar-wrapper">
              <ul>
                <NavLink to="/">
                  <li>Home</li>
                </NavLink>
                <NavLink to="/shop">
                  <li>Shop</li>
                </NavLink>
                <NavLink to="/cart">
                  <li>Cart</li>
                </NavLink>
              </ul>
            </div>
          </section>
        </>
      )}

      {/* =========================== Responsive Navbar ========================= */}

      {tablet === true && (
        <>
          <section className="responsive-nav-top">
            <div className="responsive-nav-top-wrapper">
              <div className="text">
                <p>FREE SHIPPING FOR ALL ORDERS OF $150</p>
              </div>
              <div className="cart-logo">
                <Link to="/cart">
                  <AiOutlineShopping onClick={addCart} />
                  <span>{count}</span>
                </Link>
              </div>
            </div>
          </section>

          <section className="responsive-main-nav">
            <div className="responsive-main-nav-wrapper">
              <div className="logo">
                <Link to="/">
                  <img src="images/logo.png" alt="logo" />
                </Link>
              </div>
              <div className="responsive-nav-icons" onClick={toggleMenu}>
                {open ? (
                  <AiOutlineAlignRight />
                ) : (
                  <div className="responsive-nev-links">
                    <AiOutlineClose />
                    <ul>
                      <NavLink to="/">
                        <li>Home</li>
                      </NavLink>
                      <NavLink to="/shop">
                        <li>Shop</li>
                      </NavLink>
                      <NavLink to="/cart">
                        <li>Cart</li>
                      </NavLink>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Header;
